body, html{
  background-color: #02af48ff;
  background: #02af48ff;;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*
h1
{
  font-size: 45px;
  color: #374657;
  font-weight: 700;
}

h2
{
  font-size: 36px;
  color: #374657;
  font-weight: 700;
}


h2 span.slash
{
  letter-spacing: 8px;
}

h3
{
  font-size: 22px;
  color: #374657;
  font-weight: 700;
}

h4
{
  font-size: 18px;
  color: #374657;
  font-weight: 700;
}

p, li
{
  font-size: 18px;
  color: #747679;
  font-weight: 400;
}

a
{
  color: inherit;
}

header *, footer *{
  text-decoration: none;
}


.Green
{
  color: #19A78E;
}
*/



.MuiButton-containedPrimary
{
  
  background-color: #02af48ff !important;
}



/**************************************** HEADER **************************************/
header, .Header
{
  background: #fff !important;
  box-shadow: none  !important;
  color: #02af48ff;
}

.Header a
{
  text-decoration: none;
}

.Header .Title
{
  font-weight: 600;
}

.Header .Title, .Header .AppsIcon, .Header .ProfileIcon, .Header  .NotificationsIcon
{
  color: #02af48ff;
}





/**************************************** Loading ************************************/
.loading-container {
  align-items: center;
  text-align: center;
  margin-top: 30px;
  color: #fff;
}

.loading-container-green {
  align-items: center;
  text-align: center;
  margin-top: 30px;
  color: #02af48ff;
}

.loading-dots {
  display: flex;
  justify-content: center;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  animation: dot-flashing 1.5s infinite;
}

.loading-container-green .dot {
  background-color: #02af48ff;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 30px;
}



/**************************************** Login ************************************/


.LoadingPage 
{
 color: #fff;
 text-align: center;
}

.LoadingPage .Heading
{
 color: #fff;
 text-align: center;
 margin: 0;
 margin-bottom: 30px;
 font-size: 40px;
}

.LoadingPage .Logo
{
  margin-top: 30px ;
  margin-bottom: 15px ;
  max-width: 150px;
  width: 100%;
}









/************************************ notifications *******************************************/


.notificationsTableIcon
{
  font-size: 20px  !important;
  color:#02af48ff !important;
  width: 20px;
  padding-right: 0 !important;
}




/************************************ Home View *******************************************/


.AddButton:hover
{
  background-color: #a3e2bc !important;
}